'use client';

import { Button, Card, Space } from 'antd';
import { SinglePageFormLayout } from '@/shared/components/layouts/SinglePageFormLayout';
import Logo from '@/shared/components/Logo';
import { useRouter } from 'next/navigation';
import { NextPageRoute } from '@/config';
import { BsGoogle } from 'react-icons/bs';
import { handleSignInWithGoogle } from '@/core/lib/firebase';

export default function LoginPage() {
  const router = useRouter();

  const handleGoogleLogin = async () => {
    const onSuccess = () => {
      router.push(NextPageRoute.Dashboard);
    };
    const onError = (error: string) => {
      alert(error);
    };

    await handleSignInWithGoogle(onSuccess, onError);
  };

  return (
    <SinglePageFormLayout>
      <Space direction="vertical">
        <Card
          bordered
          className="flex flex-col items-center"
          style={{
            minWidth: '300px',
          }}
        >
          <Logo />
          <Button
            type="primary"
            onClick={() => handleGoogleLogin()}
            icon={<BsGoogle />}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Log In Using Google
          </Button>
        </Card>
      </Space>
    </SinglePageFormLayout>
  );
}
