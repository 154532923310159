import { usePageTracking } from '@/core/lib/analytics/google-analytics';
import { Layout as AntdLayout } from 'antd';
import React from 'react';
import Head from 'next/head';
import { AppConfig } from '@/config';
import { ToastHolder } from '@/core/lib/feedback-component/toast';
import { NotificationHolder } from '@/core/lib/feedback-component/notification';

const { Content, Footer } = AntdLayout;

interface ISinglePageFormLayoutProps {
  title?: string;
  children: React.ReactNode;
}

export const SinglePageFormLayout: React.FC<ISinglePageFormLayoutProps> = ({
  children,
  title,
}) => {
  usePageTracking();

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Head>
        <title>{title || AppConfig.CONSTANTS.APP_NAME}</title>
      </Head>
      <AntdLayout className="site-layout">
        <Content
          className="flex items-center justify-center"
          style={{
            margin: '0 1.5rem',
          }}
        >
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>MetaPals ©2024</Footer>
        <ToastHolder />
        <NotificationHolder />
      </AntdLayout>
    </AntdLayout>
  );
};
